import { TransportInterface, BufferedTransport } from './transport';
import { CustomDimensions, TrackRequest, TrackRequest_Payload, PageView, Event, ScreenView, UserTiming, ProductImpression, WorkerMessage, WorkerMessageType, WorkerConfig, WorkerPayload } from './messages';

import Worker from "./core.worker";

class WorkerTransport implements TransportInterface {
  private worker: Worker

  constructor(worker: Worker) {
    this.worker = worker;
  }

  send(...ps: TrackRequest_Payload[]) {
    if (!this.worker) {
      console.warn("worker transport has no worker");
    }
    let msg: WorkerMessage = {
      type: WorkerMessageType.WorkerPayload,
      payload: {
        payloads: ps,
      },
    }
    try {
      this.worker.postMessage(msg);
    } catch (err) {
      console.error('worker transport post message error', err);
    }
  }

  flush(): Promise<any> {
    return Promise.resolve();
  }

  sendSync(...ps: TrackRequest_Payload[]): Promise<any> {
    return Promise.resolve();
  }
}

// Client is the main analytics service client. 
// It is designed to be able to run on main browser thread or in a worker thread.
export class Client {
  // indicate whether the client is initiated.
  private initialized: boolean = false
  private endpoint: string

  private appName?: string
  private appID?: string
  private appVersion?: string
  private appInstallerID?: string

  private transport: TransportInterface

  private workerEnabled: Boolean
  private worker?: Worker

  private oldXHROpen?: any
  private xhrTimingVerifyReqID: boolean = true

  private interceptedXHRInitialized: boolean = false
  private observingTitleAndAddressForPageView: boolean = false
  private observingError: boolean = false

  constructor(
    endpoint: string,
    transport?: TransportInterface,
    enableXHRTiming: boolean = false,
    xhrTimingVerifyReqID: boolean = true,
    autoPageView: boolean = false,
    observingError: boolean = true,
  ) {
    this.endpoint = endpoint;

    // will enable worker if possible.
    if (window && window.Worker) {
      this.worker = new Worker();
      this.workerEnabled = true;
      // send the config to worker
      let config: WorkerMessage = {
        type: WorkerMessageType.WorkerConfig,
        config: {
          endpoint: endpoint,
        },
      }
      this.worker.postMessage(config);

      this.transport = new WorkerTransport(this.worker);
    } else {
      // no worker
      if (!transport) {
        this.transport = new BufferedTransport(endpoint);
      } else {
        this.transport = transport;
      }
    }

    if (enableXHRTiming) {
      this.xhrTimingVerifyReqID = xhrTimingVerifyReqID;
      this.interceptXHR();
    }

    if (autoPageView) {
      this.observePageView();
    }

    if (observingError) {
      this.observeError();
    }
  }

  configScreenApp(appName?: string, appID?: string, appVersion?: string, appInstallerID?: string) {
    this.appName = appName;
    this.appID = appID;
    this.appVersion = appVersion;
    this.appInstallerID = appInstallerID;
  }

  private trySend(...ps: TrackRequest_Payload[]) {
    try {
      this.transport.send(...ps);
    } catch (err) {
      console.error('transport post message error', err);
    }
  }

  pageView(
    title?: string,
    location?: string,
    page?: string,
    customDimensions?: CustomDimensions,
    timeMs?: number,
  ) {
    let pv: PageView = {
      t: title,
      l: location,
      p: page,
    }

    const pl: TrackRequest_Payload = {
      ts: timeMs ? timeMs : Date.now(),
      pv: pv,
      cd: customDimensions,
    }
    this.trySend(pl);
  }

  event(
    category?: string,
    action?: string,
    label?: string,
    value?: number,
    requestID?: string,
    customDimensions?: CustomDimensions,
    timeMs?: number,
  ) {
    let e: Event = {
      c: category,
      a: action,
      l: label,
      v: value,
      rid: requestID,
    }
    const pl: TrackRequest_Payload = {
      ts: timeMs ? timeMs : Date.now(),
      e: e,
      cd: customDimensions,
    }
    this.trySend(pl);
  }

  screenView(
    screenName?: string,
    customDimensions?: CustomDimensions,
    timeMs?: number,
  ) {
    let sv: ScreenView = {
      n: this.appName,
      aid: this.appID,
      av: this.appVersion,
      aiid: this.appInstallerID,
      sn: screenName,
    }
    const pl: TrackRequest_Payload = {
      ts: timeMs ? timeMs : Date.now(),
      sv: sv,
      cd: customDimensions,
    }
    this.trySend(pl);
  }

  userTiming(
    category?: string,
    variable?: string,
    label?: string,
    valueMs?: number,
    customDimensions?: CustomDimensions,
    timeMs?: number,
  ) {
    let ut: UserTiming = {
      c: category,
      a: variable,
      l: label,
      v: valueMs,
    }
    const pl: TrackRequest_Payload = {
      ts: timeMs ? timeMs : Date.now(),
      ut: ut,
      cd: customDimensions,
    }
    this.trySend(pl);
  }

  productImpression(
    id?: string,
    name?: string,
    list?: string,
    brand?: string,
    category?: string,
    variant?: string,
    position?: number,
    price?: number,
    customDimensions?: CustomDimensions,
    timeMs?: number,
  ) {
    let pi: ProductImpression = {
      id: id,
      n: name,
      l: list,
      b: brand,
      c: category,
      v: variant,
      p: position,
      pc: price,
    }
    const pl: TrackRequest_Payload = {
      ts: timeMs ? timeMs : Date.now(),
      pi: pi,
      cd: customDimensions,
    }
    this.trySend(pl);
  }

  // xhr intercept
  private interceptXHR() {
    if (this.interceptedXHRInitialized === true) {
      return;
    }
    console.log('nu', 'en', 'xhr');
    const self = this;
    const origOpen = XMLHttpRequest.prototype.open;
    const origSend = XMLHttpRequest.prototype.send;

    var oldOnReadyStateChange;

    // TODO: ignore tracking requests...
    XMLHttpRequest.prototype.open = function () {
      if (!arguments || arguments.length < 2 || !arguments[1] || typeof arguments[1] !== 'string') {
        // skip
        origOpen.apply(this, arguments);
        return;
      }
      const url = arguments[1];

      if (url.includes(self.endpoint)) {
        origOpen.apply(this, arguments);
        return;
      }

      this._naURL = url;
      origOpen.apply(this, arguments);
    }

    XMLHttpRequest.prototype.send = function () {
      const url = this._naURL;
      if (!url || this._naNoIntercept === true) {
        // skip
        origSend.apply(this, arguments)
        return
      }

      const startMs = Date.now();
      function onReadyStateChange() {
        // 4 is complete
        if (this.readyState !== 4) {
          return
        }
        // complete
        if (self.xhrTimingVerifyReqID) {
          var reqID: string;
          try {
            if (this.getAllResponseHeaders().indexOf('x-nu-req-id') > -1) {
              reqID = this.getResponseHeader('x-nu-req-id');
            }
          } catch (err) {
            console.warn(err);
            return
          }
          if (!reqID) {
            console.log("open skipped");
            return
          }
          self.userTiming(
            'xhr',
            'load',
            reqID,
            Date.now() - startMs,
            {
              'url': this.responseURL,
            }
          )
        }
      }

      this.addEventListener("readystatechange", onReadyStateChange, false);
      origSend.apply(this, arguments);
    };

    this.interceptedXHRInitialized = true;
  }

  private observePageView() {
    console.log('na observing');
    if (this.observingTitleAndAddressForPageView) {
      return
    }
    var lastTitle: string, lastURL: string;
    const self = this;
    function onTitleChange() {
      if (document.title === lastTitle) {
        // ignore title changes
        return
      }
      lastTitle = document.title
      lastURL = document.URL
      self.sendAutoPageView(Date.now());
    }
    // function onHashChange() {
    //   if (document.URL === lastURL) {
    //     return
    //   }
    //   lastTitle = document.title
    //   lastURL = document.URL
    //   self.sendAutoPageView(Date.now());
    // }
    function onPopState() {
      if (document.URL === lastURL) {
        return
      }
      lastTitle = document.title
      lastURL = document.URL
      self.sendAutoPageView(Date.now());
    }
    new MutationObserver(onTitleChange).observe(document.querySelector('title'), { childList: true });
    // window.addEventListener("hashchange", onHashChange, false);
    window.addEventListener("popstate", onPopState, false);

    this.observingTitleAndAddressForPageView = true
  }

  // sendAutoPageView will grab address and title for page view event.
  private sendAutoPageView(timeMs?: number) {
    if (!window || !window.location) {
      return
    }
    const path = window.location.pathname;
    const url = window.location.href;
    this.pageView(
      document.title,
      window.location.href,
      window.location.pathname + window.location.search + window.location.hash,
      {
        by: 'na',
      },
      timeMs,
    )
  }

  private sendError(event: string, source?: string, lineno?: number, colno?: number, error?: Error) {
    var cd: CustomDimensions = {};
    try {
      if (source) {
        cd.source = source;
      }
      if (lineno) {
        cd.lineno = JSON.stringify(lineno);
      }
      if (colno) {
        cd.colno = JSON.stringify(colno);
      }
      if (error) {
        cd.error = JSON.stringify(error);
      }
    } catch (err) {
      console.warn(err);
      return
    }
    
    this.event(
      'error',
      'onerror',
      event,
      1,
      '',
      cd,
    )
  }

  private observeError() {
    console.log('na', 'oberr');
    if (this.observingError) {
      return;
    }
    const self = this;
    var oldOnError = window.onerror;
    if (typeof oldOnError !== 'function') {
      oldOnError = function(event: string, source?: string, lineno?: number, colno?: number, error?: Error) {}
    }

    window.onerror = function(event: string, source?: string, lineno?: number, colno?: number, error?: Error) {
      try {
        oldOnError(event, source, lineno, colno, error);
      } catch (err) {
        console.warn(err);
      } finally {
        self.sendError(event, source, lineno, colno, error);
      }
    }

  }
}
