import { TrackRequest, TrackRequest_Payload } from './messages';

interface BaseTransportInterface {
  sendSync(...ps: TrackRequest_Payload[]): Promise<any>
}

export interface TransportInterface extends BaseTransportInterface {
  send(...ps: TrackRequest_Payload[]): void
  flush(): Promise<any>
}

class XHRTransport implements BaseTransportInterface {
  endpoint: string
  constructor(endpoint: string) {
    this.endpoint = endpoint
  }

  sendSync(...ps: TrackRequest_Payload[]): Promise<any> {
    const method = 'POST';
    let self = this;
    // Create the XHR request
    var request = new XMLHttpRequest();

    // Return it as a Promise
    return new Promise((resolve, reject) => {
      let trackReq = {
        d: ps,
      }
      const queryInfo = `?size=${ps.length}`;
      var blob = new Blob([JSON.stringify(trackReq)], { type: 'application/json' });
      let xhr = new XMLHttpRequest();
      xhr.open(method, self.endpoint + queryInfo);
      // xhr.setRequestHeader('nuc', 'na'); // used to whitelist na requests
      (<any>xhr)._naNoIntercept = true;
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);
      xhr.send(blob);
    });
  }
}

export class BufferedTransport implements TransportInterface {
  private batchSize: number = 50;
  endpoint: string
  private baseTransport: BaseTransportInterface

  private buffer: TrackRequest_Payload[]
  private inflight: TrackRequest_Payload[]
  private sending: boolean
  private flushNext: boolean // flushNext indicate the transport should flush at the next opportunity.

  constructor(endpoint: string, baseTransport?: BaseTransportInterface, interval: number = 5000) {
    this.endpoint = endpoint;
    this.baseTransport = baseTransport;
    this.buffer = [];
    if (!this.baseTransport) {
      this.baseTransport = new XHRTransport(endpoint);
    }
    const self = this;
    let timerId = setInterval(() => {
      self.sendIfNeeded(true); // interval flushes.
    }, interval); // start the timer
  }

  send(...ps: TrackRequest_Payload[]): void {
    let self = this;
    // stuck everything into the buffer
    ps.forEach((value: TrackRequest_Payload) => {
      if (!value.ts) {
        value.ts = Date.now();
      }
      self.buffer.push(value);
    })
    this.sendIfNeeded(false);
  }

  private async finishedSending(flush: boolean, resp?: any): Promise<any> {
    this.inflight = [];
    this.sending = false;
    if (!this.flushNext) {
      return resp;
    }
    return await this.sendIfNeeded(flush);
  }

  private async sendIfNeeded(flush: boolean): Promise<any> {
    if (!flush && this.buffer.length < this.batchSize) {
      // return early
      return;
    }

    const self = this;
    const sendSize: number = this.buffer.length < this.batchSize ? this.buffer.length : this.batchSize;

    this.inflight = this.buffer.slice(0, sendSize);
    if (this.inflight.length === 0) {
      return Promise.resolve();
    }
    // TODO: find the common timestamp to save traffic.
    this.buffer.splice(0, sendSize);
    this.sending = true;
    try {
      const v = await this.sendSync(...this.inflight);
      return await self.finishedSending(flush, v);
    } catch (err) {
      console.error("na error, swallow", err);
      // throw (err);
    }
  }

  flush(): Promise<any> {
    return Promise.resolve();
  }

  sendSync(...ps: TrackRequest_Payload[]): Promise<any> {
    return this.baseTransport.sendSync(...ps);
  }
}
