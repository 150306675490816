// index.ts load the client
import { Client } from "./client";

declare global {
  interface Window { na: Client; setupNA: any; NA_ENDPOINT: string; }
}

if (window) {
  window.setupNA = function (endpoint: string, interceptXHR: boolean = true) {
    window.na = new Client(
      endpoint,
      null,
      interceptXHR,
      true,
      true,
      true,
    )
  }
  if (window.NA_ENDPOINT) {
    window.setupNA(window.NA_ENDPOINT);
  } else if (process.env["DEFAULT_ENDPOINT"]) {
    window.setupNA(process.env["DEFAULT_ENDPOINT"]);
  }
}
