export interface CustomDimensions {
  [key: string]: string,
}

export interface Event {
  c?: string;  // catagory
  a?: string;  // action
  l?: string;  // label
  v?: number;  // value
  rid?: string; // request_id
}

export interface ProductImpression {
  id?: string; // id
  n?: string;  // name
  l?: string;  // list
  b?: string;  // brand
  c?: string;  // category
  v?: string;  // variant
  p?: number;  // position
  pc?: number; // price
}

export interface UserTiming {
  c?: string; // category
  a?: string; // var
  l?: string; // label
  v?: number; // value
}

export interface ScreenView {
  n?: string;   // app_name
  aid?: string; // app_id
  av?: string;  // app_version
  aiid?: string; // app_installer_id
  sn?: string;  // screen_name
}

export interface PageView {
  t?: string; // title
  l?: string; // location
  p?: string; // page
}

// Payload is broker message payload, unlike TrackRequest,
// it will only contain one message.
export interface TrackRequest_Payload {
  ts?: number; // timestamp in milisecond.
  td?: number; // timestamp delta, if the reference timestamp is provided

  // should have one and only one of the below instantiated.
  e?: Event;
  pv?: PageView;
  sv?: ScreenView;
  ut?: UserTiming;
  pi?: ProductImpression;

  cd?: CustomDimensions;
}

// TrackRequest allows batched and delayed, as well as realtime events to be sent to the server.
export interface TrackRequest {
  tb?: number // timestamp_base
  d: TrackRequest_Payload[] // payload data
}

export interface WorkerConfig {
  endpoint: string
}

export interface WorkerPayload {
  payloads: TrackRequest_Payload[]
}

export enum WorkerMessageType {
  WorkerConfig,
  WorkerPayload,
}

export interface WorkerMessage {
  type: WorkerMessageType
  config?: WorkerConfig
  payload?: WorkerPayload
}
